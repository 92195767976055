document.addEventListener('DOMContentLoaded', () => {
  const navMain = document.querySelector('.main-nav');
  const navToggle = document.querySelector('.main-nav__toggle');

  navMain.classList.remove('main-nav--nojs');

  navToggle.addEventListener('click', () => {
    if (navMain.classList.contains('main-nav--closed')) {
      navMain.classList.remove('main-nav--closed');
      navMain.classList.add('main-nav--opened');
    } else {
      navMain.classList.add('main-nav--closed');
      navMain.classList.remove('main-nav--opened');
    }
  });

  const priceLIstHeader = document.querySelector('#price-btn');
  const priceLIstButtons = document.querySelectorAll('.price-list-btn');
  const modal = document.querySelector('#modalprice');
  const modalCloses = document.querySelectorAll('.modal__close');
  const modalBackdrops = document.querySelectorAll('.modal__backdrop');

  const modalFeedback = document.querySelector('#modalfeedback');
  const feedbackModalButtons = document.querySelectorAll('.modal-feedback-btn');

  const modalThankYou = document.querySelector('#modalthankyou');
  const modalThankYouButtons = document.querySelectorAll('.modal-thankyou-btn');

  const modalAbout = document.querySelector('#modalabout');
  const modalAboutBtns = document.querySelectorAll('.modal-about-btn');

  const modalTestDrive = document.querySelector('#modaltestdrive');
  const modalTestDriveBtns = document.querySelectorAll('.modal-test-drive-btn');

  priceLIstHeader.addEventListener('click', () => {
    if (modal.classList.contains('open')) {
      modal.classList.remove('open');
    } else {
      modal.classList.add('open');
    }
  });

  for (let index = 0; index < priceLIstButtons.length; index++) {
    const priceLIstButton = priceLIstButtons[index];
    priceLIstButton.addEventListener('click', () => {
      if (modal.classList.contains('open')) {
        modal.classList.remove('open');
      } else {
        modal.classList.add('open');
      }
    });
  }

  for (let index = 0; index < modalThankYouButtons.length; index++) {
    const modalThankYouButton = modalThankYouButtons[index];
    modalThankYouButton.addEventListener('click', () => {
      if (modalThankYou.classList.contains('open')) {
        modalThankYou.classList.remove('open');
      } else {
        modalThankYou.classList.add('open');
      }
    });
  }

  for (let index = 0; index < feedbackModalButtons.length; index++) {
    const feedbackModalButton = feedbackModalButtons[index];
    feedbackModalButton.addEventListener('click', () => {
      if (modalFeedback.classList.contains('open')) {
        modalFeedback.classList.remove('open');
      } else {
        modalFeedback.classList.add('open');
      }
    });
  }

  if (modalAbout && modalAboutBtns){
    for (let index = 0; index < modalAboutBtns.length; index++) {
      const modalAboutBtn = modalAboutBtns[index];
      modalAboutBtn.addEventListener('click', () => {
        if (modalAbout.classList.contains('open')) {
          modalAbout.classList.remove('open');
        } else {
          modalAbout.classList.add('open');
        }
      });
    }
    modalAbout.addEventListener('click', () => {
      modalAbout.classList.remove('open');
    });
  }

  if (modalTestDrive && modalTestDriveBtns){
    for (let index = 0; index < modalTestDriveBtns.length; index++) {
      const modalTestDriveBtn = modalTestDriveBtns[index];
      modalTestDriveBtn.addEventListener('click', () => {
        if (modalTestDrive.classList.contains('open')) {
          modalTestDrive.classList.remove('open');
        } else {
          modalTestDrive.classList.add('open');
        }
      });
    }
  }

  for (let index = 0; index < modalCloses.length; index++) {
    const modalClose = modalCloses[index];
    modalClose.addEventListener('click', () => {
      modal && modal.classList.remove('open');
      modalFeedback && modalFeedback.classList.remove('open');
      modalThankYou && modalThankYou.classList.remove('open');
      modalAbout && modalAbout.classList.remove('open');
      modalTestDrive && modalTestDrive.classList.remove('open');
    });
  }

  for (let index = 0; index < modalBackdrops.length; index++) {
    const modalBackdrop = modalBackdrops[index];
    modalBackdrop.addEventListener('click', () => {
      modal && modal.classList.remove('open');
      modalFeedback && modalFeedback.classList.remove('open');
      modalThankYou && modalThankYou.classList.remove('open');
      modalAbout && modalAbout.classList.remove('open');
      modalTestDrive && modalTestDrive.classList.remove('open');
    });
  }

});

